import React from "react"
import Seo from '../components/seo';
import Layout from '../containers/layout/layout'
import Banner from '../containers/projects/banner'
import Project from '../containers/projects/project'
import {pathWithLanguage} from "../utils/utilFunctions";

const ProjectPage = (props) => {
	const langKey = props.pageContext.langKey;
	return (
		<Layout lang={langKey}>
			<Seo title="Our projects" url={pathWithLanguage(langKey, `/project`)} lang={langKey} />
			<Banner />
			<Project lang={langKey}/>
		</Layout>
	)
}
export default ProjectPage
